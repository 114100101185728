<template>
  <v-card flat class="mt-2">
    <v-btn
      color="success ml-2"
      @click="showAdd"
      :disabled="adding"
      fab
      small
      class="float-right"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-data-iterator
      class="mt-2"
      :items="items"
      item-key="title"
      hide-default-footer
      :search="search"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.person.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card v-if="item.person.id > 0">
              <PersonItem v-if="item.person" :value="item.person" />
              <v-card-text>{{ item.comment }}</v-card-text>
              <v-card-actions
                ><v-spacer></v-spacer
                ><v-btn text @click="unlock(item.person)"
                  >sperre aufheben</v-btn
                ></v-card-actions
              >
            </v-card>
            <v-card v-else>
              <v-banner color="success">
                <v-autocomplete
                  v-model="newPerson"
                  :items="persons"
                  :loading="personsLoading"
                  :search-input.sync="searchPerson"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="zu sperrende Person"
                  placeholder="Tippen um Suche zu starten"
                  clearable
                  return-object
                ></v-autocomplete>
              </v-banner>

              <PersonItem v-if="newPerson" :value="newPerson" />
              <v-list v-if="newPerson">
                <v-list-item>
                  <v-textarea
                    v-model="newComment"
                    outlined
                    label="Kommentar"
                  ></v-textarea>
                </v-list-item>
              </v-list>
              <v-card-actions
                ><v-btn text @click="cancelAdding">abbruch</v-btn
                ><v-spacer></v-spacer
                ><v-btn text @click="lock" :disabled="!newPerson || !newComment"
                  >sperre erfassen</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import PersonItem from "@/components/PersonItem";

export default {
  name: "ChangesLock",
  props: ["search"],
  components: { PersonItem },
  data: () => ({
    searchPerson: null,
    loading: false,
    personsLoading: false,
    newPerson: null,
    newComment: "",
    persons: [],
    items: [],
    adding: false,
  }),
  watch: {
    async searchPerson() {
      if (this.persons.length > 0) return;
      if (this.personsLoading) return;
      this.personsLoading = true;
      this.persons = [];
      this.personsLoading = false;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "change/lock" });
      this.loading = false;
    },
    showAdd() {
      this.items.unshift({
        person: { id: 0 },
      });
      this.adding = true;
    },
    async cancelAdding() {
      await this.getData();
      this.adding = false;
    },
    async lock() {
      await this.apiPost({
        resource: "change/lock",
        data: { person: this.newPerson, comment: this.newComment },
      });
      await this.getData();
      this.adding = false;
    },
    async unlock(person) {
      if (
        this.$root.confirm({
          message:
            "Soll die Sperre von " +
            person.name +
            " wirklich aufgehoben werden?",
          color: "info",
          icon: "mdi-lock-off",
        })
      ) {
        await this.apiDelete({ resource: "change/lock", id: person.id });
        this.getData();
      }
    },
  },
  async created() {
    this.getData();
  },
};
</script>
